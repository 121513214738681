module.exports={
  "name": "@uppy/status-bar",
  "description": "A progress bar for Uppy, with many bells and whistles.",
  "version": "1.9.0",
  "license": "MIT",
  "main": "lib/index.js",
  "style": "dist/style.min.css",
  "types": "types/index.d.ts",
  "keywords": [
    "file uploader",
    "uppy",
    "uppy-plugin",
    "progress bar",
    "status bar",
    "progress",
    "upload",
    "eta",
    "speed"
  ],
  "homepage": "https://uppy.io",
  "bugs": {
    "url": "https://github.com/transloadit/uppy/issues"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/transloadit/uppy.git"
  },
  "dependencies": {
    "@transloadit/prettier-bytes": "0.0.7",
    "@uppy/utils": "file:../utils",
    "classnames": "^2.2.6",
    "lodash.throttle": "^4.1.1",
    "preact": "8.2.9"
  },
  "peerDependencies": {
    "@uppy/core": "^1.0.0"
  }
}
